import {
  Button,
  Checkbox,
  EditableDescription,
  EditableHeading,
  EditableText,
  IconButton,
  MenuItem,
  Select,
  Switch,
} from "@hightouchio/ui";

import { DeleteButton } from "src/components/delete-button";
import { LinkButton } from "src/router";

import { withPermission } from "./with-permission";

export const PermissionedButton = withPermission(Button);
export const PermissionedLinkButton = withPermission(LinkButton);
export const PermissionedMenuItem = withPermission(MenuItem);
export const PermissionedIconButton = withPermission(IconButton);
export const PermissionedSwitch = withPermission(Switch);
export const PermissionedEditableHeading = withPermission(EditableHeading);
export const PermissionedEditableDescription =
  withPermission(EditableDescription);
export const PermissionedEditableText = withPermission(EditableText);
export const PermissionedCheckbox = withPermission(Checkbox);
export const PermissionedSelect = withPermission(Select);
export const PermissionedDeleteButton = withPermission(DeleteButton);
