import { Column, FormField, SectionHeading, Switch } from "@hightouchio/ui";
import { useOutletContext, useParams } from "src/router";
import { Form, FormActions, useHightouchForm } from "src/components/form";
import { OutletContext } from ".";
import {
  useUpdateGeneralGrantsMutation,
  useUserCreationGrantsQuery,
} from "src/graphql";
import { ActionBar } from "src/components/action-bar";
import { Controller } from "react-hook-form";
import { useUser } from "src/contexts/user-context";

export const GeneralGrants = () => {
  const { isWorkspaceAdmin } = useOutletContext<OutletContext>();
  const { user } = useUser();
  const { roleId, workspaceId } = useParams();

  const updateGeneralGrants = useUpdateGeneralGrantsMutation();

  const { data: { canCreateSource, canCreateDestination, canUpdateIDR } = {} } =
    useUserCreationGrantsQuery(
      {
        userGroupId: roleId ?? "",
        workspaceId: workspaceId ?? "",
      },
      {
        select: (data) => {
          const userGroupGrants = data.user_group_workspaces_by_pk;
          return {
            canCreateSource: userGroupGrants?.can_create_sources,
            canCreateDestination: userGroupGrants?.can_create_destinations,
            canUpdateIDR: userGroupGrants?.can_update_idr,
          };
        },
        suspense: true,
      },
    );

  const form = useHightouchForm({
    values: {
      canCreateSource,
      canCreateDestination,
      canUpdateIDR,
    },
    onSubmit: async ({
      canCreateSource,
      canCreateDestination,
      canUpdateIDR,
    }) => {
      await updateGeneralGrants.mutateAsync({
        userGroupId: roleId ?? "",
        workspaceId: workspaceId ?? "",
        // we should probably be relying on triggers to do this, but for now we'll just pass the values
        canCreateSource: canCreateSource ?? false,
        canCreateDestination: canCreateDestination ?? false,
        canUpdateIDR: canUpdateIDR ?? false,
      });
    },
  });

  return (
    <Form form={form}>
      <Column p={4} flex={1} gap={6}>
        <SectionHeading>General</SectionHeading>
        <Column gap={4}>
          <Controller
            name="canCreateSource"
            defaultValue={false}
            render={({ field }) => (
              <FormField
                label="Can this role create new sources?"
                description="This setting allows users with this role to create a new source of any type and grant other groups permission to manage and sync from that source."
              >
                <Switch
                  aria-label="Allow role to create new sources."
                  isDisabled={isWorkspaceAdmin}
                  isChecked={field.value}
                  onChange={field.onChange}
                />
              </FormField>
            )}
          />
          <Controller
            name="canCreateDestination"
            defaultValue={false}
            render={({ field }) => (
              <FormField
                label="Can this role create new destinations?"
                description="This setting allows users with this role to create a new destination of any type and grant other groups permission to manage and sync to that destination."
              >
                <Switch
                  aria-label="Allow role to create new destinations."
                  isDisabled={isWorkspaceAdmin}
                  isChecked={field.value}
                  onChange={field.onChange}
                />
              </FormField>
            )}
          />
          {
            // TODO remove once feature is launched for General Audience
            // This is just to help break the feature up during development
            // Ill remove this gate in the final PR
            user?.is_admin && (
              <Controller
                name="canUpdateIDR"
                defaultValue={false}
                render={({ field }) => (
                  <FormField
                    label="Can this role manage Identity Resolution?"
                    description="This setting allows users with this role to create and manage IDR graphs, but only for sources and models where they also have similar permissions."
                  >
                    <Switch
                      aria-label="Allow role to manage Identity Resolution."
                      isDisabled={isWorkspaceAdmin}
                      isChecked={field.value}
                      onChange={field.onChange}
                    />
                  </FormField>
                )}
              />
            )
          }
        </Column>
      </Column>
      <ActionBar fit>
        <FormActions />
      </ActionBar>
    </Form>
  );
};
