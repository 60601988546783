import { Column, Row, Paragraph, Box } from "@hightouchio/ui";
import isEmpty from "lodash/isEmpty";
import { useMemo, useState } from "react";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { Labels } from "src/components/labels/labels";
import { PageSpinner } from "src/components/loading";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import {
  AudienceTemplatesBoolExp,
  AudienceTemplatesQuery,
  useAudienceTemplatesQuery,
} from "src/graphql";

import {
  Table as HightouchTable,
  Pagination,
  TableColumn,
  useTableConfig,
} from "src/ui/table";
import { LastUpdatedColumn } from "src/ui/table/columns/last-updated";
import { PreviewAudienceTemplateDrawer } from "./preview-drawer";
import {
  EmptyTemplatesState,
  NO_SEARCH_RESULTS_PLACEHOLDER,
} from "src/pages/templates/empty-templates-state";
import { useSearchParams } from "src/router";

export const AudienceTemplates = () => {
  const [searchParams] = useSearchParams();
  const search = searchParams.get("search") ?? "";

  const { limit, offset, page, setPage } = useTableConfig({ limit: 20 });
  const filter: AudienceTemplatesBoolExp = useMemo(
    () => (search ? { name: { _ilike: `%${search}%` } } : {}),
    [search],
  );
  const { data, isLoading } = useAudienceTemplatesQuery({
    where: filter,
    limit,
    offset,
  });

  if (isLoading || !data) {
    return <PageSpinner />;
  }

  return (
    <Column>
      {data.audience_templates_aggregate.aggregate?.count === 0 && !search ? (
        <EmptyTemplatesState
          docsUrl="" // TODO(nishad): add docs link when written
          newTemplateUrl="new"
          title="No audience templates have been created yet"
          message="Audience templates are reusable audience definitions that users in your workspace will be able to apply when they build audiences."
        />
      ) : (
        <Table data={data} page={page} pageSize={limit} setPage={setPage} />
      )}
    </Column>
  );
};

const Table = ({
  data,
  page,
  pageSize,
  setPage,
}: {
  data: AudienceTemplatesQuery;
  page: number;
  pageSize: number;
  setPage: (page: number) => void;
}) => {
  const [previewTemplate, setPreviewTemplate] =
    useState<AudienceTemplatesQuery["audience_templates"][number]>();

  const columns: TableColumn<
    AudienceTemplatesQuery["audience_templates"][number]
  >[] = [
    {
      name: "Name",
      cell: ({ name, description, labels }) => (
        <Column gap={0.5} overflowX="hidden" py={4}>
          <TextWithTooltip fontWeight="medium">{name}</TextWithTooltip>
          <Box as={Paragraph} color="text.secondary" sx={{ textWrap: "wrap" }}>
            {description}
          </Box>
          {!isEmpty(labels) && <Labels labels={labels} />}
        </Column>
      ),
      cellSx: {
        pl: "4 !important",
      },
      headerSx: {
        "&:first-of-type": {
          pl: 4,
        },
      },
      max: "4fr",
    },
    {
      name: "Model",
      max: "2fr",
      cell: ({ parent_model }) => (
        <Row gap={2} width="100%">
          <IntegrationIcon
            src={parent_model?.connection?.definition?.icon}
            name={parent_model?.connection?.definition?.name}
            size={5}
          />
          <TextWithTooltip textTransform="capitalize">
            {parent_model.name}
          </TextWithTooltip>
        </Row>
      ),
    },
    LastUpdatedColumn,
  ];

  return (
    <Column px={6}>
      <HightouchTable
        columns={columns}
        data={data.audience_templates}
        placeholder={NO_SEARCH_RESULTS_PLACEHOLDER}
        onRowClick={(row) => {
          setPreviewTemplate(row);
        }}
      />
      <Row justifyContent="right" mr={8} mt={4}>
        <Pagination
          count={data.audience_templates_aggregate.aggregate?.count}
          label="Audience templates"
          page={page}
          rowsPerPage={pageSize}
          setPage={setPage}
        />
      </Row>
      {previewTemplate && (
        <PreviewAudienceTemplateDrawer
          templateData={previewTemplate}
          isOpen={Boolean(previewTemplate)}
          onClose={() => setPreviewTemplate(undefined)}
        />
      )}
    </Column>
  );
};
