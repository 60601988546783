import { FC, MouseEvent, ReactNode, useMemo, useState } from "react";

import {
  AudienceIcon,
  Badge,
  Box,
  Button,
  Column,
  ConfirmationDialog,
  CopyIcon,
  DeleteIcon,
  Dialog,
  InformationIcon,
  Paragraph,
  Row,
  Tooltip,
  useToast,
  WarningIcon,
} from "@hightouchio/ui";
import pluralize from "pluralize";
import { useFormContext } from "react-hook-form";
import { getOutgoers } from "reactflow";
import { useNavigate, useParams } from "src/router";

import { IconBox } from "src/components/icon-box";
import { PermissionedIconButton } from "src/components/permission";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import {
  JOURNEY_NODES_WITH_BRANCH_NODES,
  NODE_WIDTH,
} from "src/pages/journeys/constants";
import { useGraphContext } from "src/pages/journeys/graph/use-graph-context";
import {
  TileNodeSourceHandle,
  TileNodeTargetHandle,
} from "src/pages/journeys/handles";
import {
  JourneyGraph,
  JourneyNodeDetails,
  NodeColor,
} from "src/pages/journeys/types";
import { JourneyNodeConfig, JourneyNodeType } from "src/types/journeys";
import { abbreviateNumber } from "src/utils/numbers";

import { NodeDescription } from "./descriptions";
import { useFlags } from "launchdarkly-react-client-sdk";

const SELECTED_NODE_BOX_SHADOW =
  "0px 0px 0px 3px #E3F2F4, 0 1px 3px #1018281A, 0 1px 2px #1018280F";

type TileNodeProps = {
  children?: ReactNode;
  colorScheme: NodeColor;
  data: JourneyNodeDetails<JourneyNodeConfig>;
  icon: JSX.Element;
  isCloneable?: boolean;
  isDeleteable?: boolean;
  isSource?: boolean;
  isSourceHandleDisabled?: boolean;
  isTarget?: boolean;
  selected?: boolean;
  dragging?: boolean;
};

export const TileNode: FC<TileNodeProps> = ({
  children,
  colorScheme,
  icon,
  isSource = false,
  isSourceHandleDisabled = false,
  isTarget = false,
  isCloneable = true,
  isDeleteable = true,
  selected = false,
  dragging = false,
  data,
}) => {
  const { node_id } = useParams<{ node_id?: string }>();
  const navigate = useNavigate();
  const { toast } = useToast();

  const form = useFormContext<JourneyGraph>();

  const {
    nodes,
    connectionSourceNodeId,
    hasOutgoers,
    isEditMode,
    isValidConnection,
    nodeErrors,
    nodeWarnings,
    nodeRunErrors,
    unauthorized: canUpdateJourney, // TODO: Remove redundant state, since permissions are in context too
    unauthorizedTooltip,
    updateJourneyPermission,
    onSelectNodes,
    onCloneNodes,
    onRemoveNode,
  } = useGraphContext();

  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [isHoveringNode, setIsHoveringNode] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const { id, name, number_users } = data;
  const hasUsers = number_users && Number(number_users) > 0;
  const isNodeSelected = node_id === id || (isEditMode && selected);

  // Re-use the existing cloning flag for sync cloning.
  const { journeysFullCloning } = useFlags();

  const edges = form.getValues("edges");

  // Show branch errors on parent node
  const branchErrors = useMemo(() => {
    if (data.config.type !== JourneyNodeType.SegmentBranch) return [];

    // TODO(XXX: Don't need to do this. Node can be passed in as a prop.
    const node = nodes.find(({ id }) => id === data.id);

    if (!node) return [];

    const branchNodes = getOutgoers(node, nodes, edges);

    return branchNodes.flatMap(
      ({ id }) =>
        nodeRunErrors
          .filter(({ node_id }) => node_id === id)
          .map(({ error }) => error)
          .filter((error): error is string => error !== null), // Filter out null values
    );
  }, [data, nodes, edges]);

  const hasValidationErrors = nodeErrors[id];
  const hasNodeWarnings = nodeWarnings && nodeWarnings[id];
  const errors: string[] = nodeRunErrors
    .filter(({ node_id }) => node_id === id)
    .map(({ error }) => error)
    .filter((error): error is string => error !== null) // Filter out null values
    .concat(branchErrors);

  const validConnectionPossible = useMemo(() => {
    if (!isHoveringNode || !connectionSourceNodeId) return true;

    // Note: If this is not performant, the handle also exposes a 'valid' class name that could be used instead.
    // https://reactflow.dev/api-reference/components/handle#style-handles-when-connecting
    return isValidConnection({
      source: connectionSourceNodeId,
      target: id,
      sourceHandle: null,
      targetHandle: null,
    });
  }, [id, isValidConnection, connectionSourceNodeId, isHoveringNode]);

  const hasConnections = hasOutgoers(id);
  const isInvalid = Boolean(hasValidationErrors || errors.length > 0);
  const isSourceNode = connectionSourceNodeId === id;
  const showBorder = isNodeSelected || isInvalid || hasNodeWarnings;
  const attemptingConnection = connectionSourceNodeId && !isSourceNode;
  const border = showBorder ? "2px solid" : undefined;
  const baseBorderColor = isInvalid
    ? "danger.base"
    : hasNodeWarnings
      ? "warning.base"
      : isNodeSelected
        ? "primary.base"
        : "transparent";
  const connectionValidationBorderColor =
    validConnectionPossible && isTarget ? "success.border" : "danger.base";

  const cloneNode = (event: MouseEvent) => {
    event.stopPropagation();

    onCloneNodes([id], journeysFullCloning);

    toast({
      id: "clone-node",
      title: "Tile cloned",
      variant: "success",
    });
  };

  const deleteNode = () => {
    onRemoveNode(id);

    toast({
      id: "delete-node",
      title: "Tile deleted",
      variant: "success",
    });
  };

  return (
    <>
      <Row
        position="relative"
        minWidth={`${NODE_WIDTH}px`}
        maxWidth={`${NODE_WIDTH}px`}
        background="white"
        borderRadius="4px 6px 6px 4px"
        // TODO(XXX): Use .selected-node class instead
        boxShadow={isNodeSelected ? SELECTED_NODE_BOX_SHADOW : "xs"}
        _hover={{
          ".tile-border": {
            cursor: dragging ? "grabbing" : "pointer",
            border: attemptingConnection ? "2px solid" : undefined,
            borderColor: connectionSourceNodeId
              ? connectionValidationBorderColor
              : baseBorderColor,
            boxShadow: isNodeSelected ? SELECTED_NODE_BOX_SHADOW : "sm",
          },
          ".tile-actions": {
            display: attemptingConnection ? "none" : "flex",
          },
        }}
        _active={{
          ".tile-border": {
            cursor: "grabbing",
            border,
            borderColor: isNodeSelected ? "primary.base" : baseBorderColor,
            boxShadow: isNodeSelected ? SELECTED_NODE_BOX_SHADOW : "md",
          },
        }}
        onMouseEnter={() => setIsHoveringNode(isEditMode)}
        onMouseLeave={() => setIsHoveringNode(false)}
        onClick={(event) => {
          if (!event.shiftKey) {
            onSelectNodes([id]);
            navigate(id);
          }
        }}
      >
        <Box
          className="tile-border"
          position="absolute"
          borderRadius="2px 6px 6px 2px"
          height="100%"
          width="100%"
          border={border}
          borderColor={baseBorderColor}
        />
        <Box bg={`${colorScheme}.400`} width="4px" borderRadius="2px 0 0 2px" />
        <Column p={4} gap={4} minWidth={0} flex={1}>
          <Row
            align="center"
            justify="space-between"
            gap={2}
            overflowX="hidden"
          >
            <Row align="center" gap={2} overflowX="hidden">
              <IconBox bg={`${colorScheme}.400`} icon={icon} />
              <TextWithTooltip
                color={`${colorScheme}.600`}
                fontWeight="semibold"
                size="lg"
              >
                {name}
              </TextWithTooltip>
            </Row>
            {hasNodeWarnings && (
              <Tooltip message="Users might never be synced">
                <Box as={WarningIcon} boxSize={4} color="warning.600" />
              </Tooltip>
            )}

            {!isEditMode ? (
              <Box as={Badge} flexShrink={0}>
                <AudienceIcon />
                {abbreviateNumber(Number(number_users))}
              </Box>
            ) : !canUpdateJourney &&
              isEditMode &&
              (isCloneable || isDeleteable) ? (
              <Row className="tile-actions" display="none">
                <PermissionedIconButton
                  aria-label="Duplicate tile."
                  permission={updateJourneyPermission}
                  unauthorizedTooltip={unauthorizedTooltip}
                  tooltip="Duplicate tile"
                  icon={CopyIcon}
                  size="sm"
                  onClick={cloneNode}
                />
                <PermissionedIconButton
                  aria-label="Delete tile."
                  tooltip="Delete tile"
                  permission={updateJourneyPermission}
                  unauthorizedTooltip={unauthorizedTooltip}
                  icon={DeleteIcon}
                  size="sm"
                  variant="danger"
                  onClick={(event) => {
                    event.stopPropagation();
                    setShowDeleteConfirmation(true);
                  }}
                />
              </Row>
            ) : null}
          </Row>

          <NodeDescription {...data} />
          {children}
          {isInvalid && nodeRunErrors.length > 0 && (
            <Row>
              <Button
                icon={InformationIcon}
                variant="warning"
                size="sm"
                onClick={(event) => {
                  event.stopPropagation();
                  setShowErrorDialog(true);
                }}
              >
                View error
              </Button>
            </Row>
          )}
        </Column>

        {isSource && (
          <TileNodeSourceHandle
            enabled={
              isEditMode &&
              !isSourceHandleDisabled &&
              !JOURNEY_NODES_WITH_BRANCH_NODES.includes(data.config.type) &&
              !hasConnections
            }
            opacity={
              JOURNEY_NODES_WITH_BRANCH_NODES.includes(data.config.type)
                ? 0
                : undefined
            }
            colorScheme={colorScheme}
          />
        )}

        {isTarget && <TileNodeTargetHandle enabled={isEditMode} />}
      </Row>

      <Dialog
        isOpen={showErrorDialog}
        title="Journey tile error details"
        variant="info"
        actions={
          <Button variant="primary" onClick={() => setShowErrorDialog(false)}>
            Close
          </Button>
        }
        onClose={() => setShowErrorDialog(false)}
      >
        <Column gap={4}>
          {errors.map((error, index) => (
            <Column key={index}>
              <Paragraph>{error}</Paragraph>
            </Column>
          ))}
        </Column>
      </Dialog>

      <ConfirmationDialog
        isOpen={showDeleteConfirmation}
        title={hasUsers ? "Delete tile with members" : "Delete tile"}
        confirmButtonText="Delete"
        variant="danger"
        onConfirm={deleteNode}
        onClose={() => setShowDeleteConfirmation(false)}
      >
        <Paragraph>
          {hasUsers
            ? `This tile contains ${pluralize(
                "member",
                Number(number_users),
                true,
              )} right now. After deleting this tile, all members will exit the journey. Are you sure you want to delete this tile?`
            : "Are you sure you want to delete this tile?"}
        </Paragraph>
      </ConfirmationDialog>
    </>
  );
};
