// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { Outlet, RouteObject } from "react-router-dom";

import { App } from "src/components/app";
import { Passwordless } from "src/components/auth/passwordless";
import { Layout } from "src/components/layout";
import { Expired } from "src/pages/expired";
import { Invalid } from "src/pages/invalid";
import Login from "src/pages/login";
import { NotFound } from "src/pages/not-found";
import PartnerConnect from "src/pages/partner-connect";
import RedirectPage from "src/pages/redirect";
import { Restricted } from "src/pages/restricted";
import { Signup } from "src/pages/signup";
import SSO from "src/pages/sso";
import { Unauthorized } from "src/pages/unauthorized";
import { Welcome, WelcomeWizard } from "src/pages/welcome";
import { routes as workspaceRoutes } from "src/pages/workspaces";
import { routes as partnerRoutes } from "src/partner";

import { jsonSlugRoutes } from "./json-slug-routes";
import { AuthenticatedRoute } from "./authenticated-route";
import { RouteErrorBoundary } from "./error-boundary";
import { RootIndex } from "./root-index";
import { SlugIndex } from "./slug-index";
import * as SlugLayout from "./slug-layout";
import { SlugRoutes } from "./slug-routes";
import { UnauthenticatedRoute } from "./unauthenticated-route";

const RootLayout = () => {
  return (
    <App>
      <Outlet />
    </App>
  );
};

export const routes: Array<RouteObject> = [
  {
    element: <RootLayout />,
    children: [
      { index: true, element: <RootIndex /> },
      { path: "*", element: <NotFound /> },
      {
        path: "/welcome",
        element: (
          <AuthenticatedRoute>
            <Welcome />
          </AuthenticatedRoute>
        ),
      },
      {
        path: "/welcome/:step",
        element: (
          <AuthenticatedRoute>
            <WelcomeWizard />
          </AuthenticatedRoute>
        ),
      },
      { path: "/unauthorized", element: <Unauthorized /> },
      { path: "/expired", element: <Expired /> },
      { path: "/invalid", element: <Invalid /> },
      { path: "/restricted", element: <Restricted /> },
      { path: "/redirect", element: <RedirectPage /> },
      {
        element: (
          <AuthenticatedRoute>
            <PartnerConnect />
          </AuthenticatedRoute>
        ),
        path: "/partner-connect/:uuid",
      },

      {
        path: "/login",
        element: (
          <UnauthenticatedRoute>
            <Login />
          </UnauthenticatedRoute>
        ),
      },
      {
        path: "/signup/email",
        element: (
          <UnauthenticatedRoute>
            <Passwordless />
          </UnauthenticatedRoute>
        ),
      },
      {
        path: "/signup",
        element: (
          <UnauthenticatedRoute>
            <Signup />
          </UnauthenticatedRoute>
        ),
      },
      {
        path: "/login/email",
        element: (
          <UnauthenticatedRoute>
            <Passwordless />
          </UnauthenticatedRoute>
        ),
      },
      {
        path: "/sso",
        element: (
          <UnauthenticatedRoute>
            <SSO />
          </UnauthenticatedRoute>
        ),
      },
      {
        path: "/sso/:org",
        element: (
          <UnauthenticatedRoute>
            <SSO />
          </UnauthenticatedRoute>
        ),
      },
      partnerRoutes,
      workspaceRoutes,
      {
        path: "/:root_workspace_slug/*",
        loader: SlugLayout.loader,
        element: (
          <AuthenticatedRoute>
            <SlugLayout.Element />
          </AuthenticatedRoute>
        ),
        children: [
          { index: true, element: <SlugIndex /> },
          {
            element: <Layout />,
            errorElement: (
              <Layout>
                <RouteErrorBoundary />
              </Layout>
            ),
            children: [
              {
                path: "*",
                element: <SlugRoutes />,
              },
              // Add new style routes here
              ...jsonSlugRoutes,
            ],
          },
        ],
      },
    ],
  },
];
